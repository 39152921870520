<template>
  <mobile-screen
    :header="true"
    screen-class="gray-bg icon-app1 app-versions"
    v-if="selectedApplication && selectedApplication.id"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="app-versions-header icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: 'r_about' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ selectedApplication.name }}
        </div>
        <template v-slot:right>
          <router-link
            :to="{ name: 'r_add-version' }"
            v-if="!subdomain && !selectable"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
    </template>
    <ul
      class="clebex-item-section"
      :class="
        !selectedAppVersionsCopy.length ? 'clebex-item-section-borderless' : ''
      "
    >
      <li
        class="clebex-item-section-item full-right-underline no-decoration-link"
        v-for="(version, i) in selectedAppVersionsCopy"
        :key="version.id"
      >
        <div class="clebex-item-content-wrapper">
          <div class="checkbox tiny alt block" v-if="selectable">
            <input
              type="checkbox"
              :id="`version${version.id}`"
              :name="`version${version.id}`"
              :selected="selectedVersions.includes(version.id)"
              :value="version.id"
            />
            <label
              :for="`version${version.id}`"
              @click="selectVersion(version.id)"
            >
              <icon icon="#cx-app1-checkmark" />
            </label>
          </div>
          <div class="clebex-version-row-wrapper">
            <div class="clebex-version-row">
              <div class="label clebex-app-version-number">
                {{ version.number }}
              </div>
              <div class="label clebex-app-version-date">
                <div class="clebex-version-push-right">
                  {{ displayDate(version.date) }}
                </div>
              </div>
            </div>
            <div
              class="label clebex-app-version-description"
              :for="`version${version.id}`"
              :class="moreDescription[i] ? 'more-description' : ''"
            >
              {{ version.description }}
            </div>
            <div
              class="label clebex-app-version-more"
              @click="moreDescription[i] = !moreDescription[i]"
            >
              {{
                moreDescription[i]
                  ? displayLabelName("about", "app-versions", "less")
                  : displayLabelName("about", "app-versions", "more")
              }}
            </div>
          </div>
          <span
            class="follow-up-icons cursor-pointer"
            @click="goToVersionDetails(version)"
          >
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-arrow-right-12x12" width="12" height="12" />
            </span>
          </span>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu">
        <ul class="actions-list">
          <li class="action" v-if="!subdomain" @click="selectableHandle()">
            <button
              class="action-btn"
              :class="selectable ? 'selectable' : ''"
              :disabled="!selectedAppVersionsCopy.length"
            >
              {{ displayLabelName("about", "app-versions", "select") }}
            </button>
          </li>
          <li
            class="action"
            v-if="!subdomain && selectable && selectedVersions.length"
          >
            <button class="action-btn delete-app" @click="show = true">
              <icon icon="#cx-app1-delete-02-14x14" class="active" />
            </button>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :confirm-action="deleteSelectedVersions"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancel-button-label="displayLabelName('global.buttons.cancel')"
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("about", "app-versions", "delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("about", "app-versions", "delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { mapActions, mapState, mapGetters } from "vuex";
import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import { formatDate } from "@/services/helpers";

export default {
  name: "DisplayVersions",
  data() {
    return {
      subdomain: getSubdomain(),
      moreDescription: [],
      selectedVersions: [],
      selectable: false,
      show: false,
      selectedAppVersionsCopy: []
    };
  },
  created() {
    if (this.selectedApplication === null) {
      this.$router.push({ name: "r_about" });
    } else {
      this.setVersions();
    }
  },
  computed: {
    ...mapState("applications", [
      "selectedAppVersions",
      "selectedApplication",
      "selectedVersion"
    ]),
    ...mapGetters("settings", ["globalDateFormat"])
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  methods: {
    ...mapActions("applications", [
      "getAppVersions",
      "setSelectedVersion",
      "getApplications"
    ]),
    goToVersionDetails(version) {
      this.setSelectedVersion(version);
      this.selectedVersion !== null
        ? this.$router.push({ name: "r_application-version" })
        : "";
    },
    selectVersion(id) {
      if (this.selectedVersions.includes(id)) {
        this.selectedVersions = this.selectedVersions.filter(
          item => item !== id
        );
      } else {
        this.selectedVersions.push(id);
      }
    },
    deleteSelectedVersions() {
      const promises = [];
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.selectedVersions.forEach(verId => {
        const promise = new Promise((resolve, reject) => {
          httpServiceAuth
            .delete(`${apiEndpoints.master.versions}/${verId}`)
            .then(() => {
              resolve(true);
            })
            .catch(error => {
              reject(false);
              if (error.response) {
                errorHandler(error.response);
              }
            });
        });
        promises.push(promise);
      });
      this.selectedVersions = [];
      Promise.all(promises).then(() => {
        this.getAppVersions(this.selectedApplication.id);
        this.getApplications()
          .catch(error => {
            if (error.response) {
              errorHandler(error.response);
            }
          })
          .finally(() => {
            this.selectableHandle();
            this.$store.commit("loader/setScreenLoading", false, {
              root: true
            });
          });
        this.$router.push({ name: "r_application-versions" });
      });
    },
    setVersions() {
      this.selectedAppVersionsCopy = JSON.parse(
        JSON.stringify(this.selectedAppVersions)
      );
      if (!this.selectedAppVersionsCopy) {
        return;
      }
      this.selectedAppVersionsCopy.sort(
        (a, b) =>
          (a.date === null) - (b.date === null) ||
          -(a.date > b.date) ||
          +(a.date < b.date)
      );
      this.selectedAppVersionsCopy.forEach(() => {
        this.moreDescription = [];
        this.moreDescription.push(false);
      });
    },
    selectableHandle() {
      if (this.selectable) {
        this.selectedVersions = [];
      }
      this.selectable = !this.selectable;
    },
    displayDate(date) {
      if (!date) {
        return "";
      } else if (!getSubdomain()) {
        return date;
      }
      return formatDate(date, this.globalDateFormat);
    }
  },
  watch: {
    selectedAppVersions: {
      deep: true,
      handler() {
        this.setVersions();
      }
    }
  }
};
</script>
